<template>
  <v-col :sm="sm" :xs="xs" :md="md" :lg="lg" :cols="cols">
    <v-tooltip
      top
      max-width="250"
      nudge-top="-15"
      transition="fade-transition"
      open-delay="500"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-card tile v-bind="attrs" v-on="on">
          <v-card-text>
            <div class="text--primary force-single-line relative pr-4">
              Name: {{ subLibrary.name }}

              <router-link :to="`/sub-libraries/${subLibrary._id}`">
                <v-icon class="absolute-right pointer" size="15" color="primary"
                  >mdi-pencil</v-icon
                >
              </router-link>
            </div>
            <div
              :class="[
                'force-single-line',
                subLibrary.booksInStock > 0 && 'green--text'
              ]"
            >
              Books In Stock: {{ subLibrary.booksInStock }}
            </div>
            <div
              class="force-single-line"
              :class="[
                'force-single-line',
                subLibrary.booksDistributed > 0 && 'orange--text'
              ]"
            >
              Book Out of Stock: {{ subLibrary.booksDistributed }}
            </div>
          </v-card-text>
        </v-card> </template
      >{{ subLibrary.name }}
    </v-tooltip>
  </v-col>
</template>

<script>
import Moment from "moment";

export default {
  props: ["lg", "md", "sm", "xs", "cols", "subLibrary"],
  setup() {
    return { Moment };
  }
};
</script>
