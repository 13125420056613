<template>
  <div style="height: 100%">
    <v-sheet
      style="height: 50px"
      class="px-6 py-2 d-flex flex-column justify-center"
    >
      <div class="d-flex justify-end">
        <v-btn tile x-small class="primary" @click="showAddSubLibraryPopup"
          >Add New Sub Library</v-btn
        >
      </div>
    </v-sheet>
    <v-container v-if="noRecordsFound" class="text-center py-10">
      No sub libraries found.
    </v-container>
    <div class="users-listing-parent" ref="scrollable" v-bar>
      <div>
        <v-container v-if="!isLoading">
          <v-row>
            <SubLibraryCard
              v-for="subLibrary in data"
              :key="subLibrary._id"
              :subLibrary="subLibrary"
              sm="6"
              xs="12"
              md="4"
              lg="4"
              cols="12"
            />
          </v-row>
        </v-container>
        <SkeltonLoader v-else cardHeight="98" loaderType="table-cell, text" />
      </div>
    </div>
    <v-dialog v-model="newSubLibraryDialog" width="700">
      <template v-slot:default="dialog">
        <v-card>
          <v-form
            autocomplete="off"
            ref="form"
            v-model="formValid"
            lazy-validation
            @submit.prevent="register"
          >
            <v-toolbar color="primary" dark class="mb-4">
              <span>Add New Sub Library</span>
            </v-toolbar>
            <div class="px-8 pb-3" style="max-height: 460px; overflow-y: auto;">
              <input
                type="email"
                name="user_name"
                id="user_name"
                value=""
                class="opacity-hide"
              />
              <input
                type="password"
                name="password"
                id="password"
                value=""
                class="opacity-hide"
              />
              <v-text-field
                v-model="formData.name"
                :rules="nameRules"
                label="Library Name"
                required
                tabindex="1"
                class="my-1"
              ></v-text-field>
              <v-text-field
                v-model="formData.email"
                :rules="emailRules"
                label="Email"
                required
                tabindex="1"
                class="my-1"
              ></v-text-field>
              <v-text-field
                v-model="formData.username"
                :rules="usernameRules"
                label="Username"
                required
                tabindex="1"
                class="my-1"
              ></v-text-field>

              <v-autocomplete
                v-model="formData.member"
                clearable
                :items="membersData"
                :loading="isMemberLoading"
                :search-input.sync="memberSearchKey"
                :filter="filterMembers"
                item-text="name"
                item-value="_id"
                label="Member assigned to this library"
                dense
                :rules="[required]"
              >
                <template v-slot:item="data">
                  <v-list-item-content>
                    <v-list-item-title
                      v-html="`# ${data.item.memberNumber} ${data.item.name}`"
                    ></v-list-item-title>
                  </v-list-item-content>
                </template>
              </v-autocomplete>
              <v-textarea
                v-model="formData.address"
                rows="1"
                :rules="[required]"
                label="Address"
                required
                tabindex="1"
                class="my-1"
              ></v-textarea>
              <v-select
                v-model="formData.district"
                :rules="[required]"
                label="District"
                :items="districts"
                tabindex="1"
                class="my-1"
              ></v-select>
              <v-text-field
                v-model="formData.pin"
                v-mask="'######'"
                :rules="[
                  required,
                  (v) => (v.length === 6 ? true : 'Enter a valid Pin Code')
                ]"
                label="Pin Code"
                required
                tabindex="1"
                class="my-1"
              ></v-text-field>
              <v-text-field
                v-model="formData.phone"
                v-mask="'############'"
                :rules="[
                  required,
                  (v) =>
                    v.length >= 10
                      ? true
                      : 'Enter a valid Indian phone number without country code'
                ]"
                label="Phone"
                required
                tabindex="1"
                class="my-1"
              ></v-text-field>
              <v-text-field
                type="password"
                v-model="formData.password"
                :rules="passwordRules"
                label="Password"
                required
                tabindex="1"
                class="my-1"
              ></v-text-field>
              <v-text-field
                type="password"
                v-model="formData.confirmPassword"
                :rules="confirmPasswordRules"
                label="Confirm Password"
                required
                tabindex="1"
                class="my-1"
              ></v-text-field>
            </div>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" text @click="dialog.value = false">
                Cancel
              </v-btn>
              <v-btn tile class="primary" type="action">Save</v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </template>
    </v-dialog>
  </div>
</template>

<script>
import SubLibraryCard from "@/components/SubLibraryCard.vue";
import SkeltonLoader from "@/components/SkeltonLoader.vue";
import { useSubLibraries } from "@/modules";

export default {
  setup: useSubLibraries,
  components: { SubLibraryCard, SkeltonLoader }
};
</script>
