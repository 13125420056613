var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"sm":_vm.sm,"xs":_vm.xs,"md":_vm.md,"lg":_vm.lg,"cols":_vm.cols}},[_c('v-tooltip',{attrs:{"top":"","max-width":"250","nudge-top":"-15","transition":"fade-transition","open-delay":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({attrs:{"tile":""}},'v-card',attrs,false),on),[_c('v-card-text',[_c('div',{staticClass:"text--primary force-single-line relative pr-4"},[_vm._v(" Name: "+_vm._s(_vm.subLibrary.name)+" "),_c('router-link',{attrs:{"to":("/sub-libraries/" + (_vm.subLibrary._id))}},[_c('v-icon',{staticClass:"absolute-right pointer",attrs:{"size":"15","color":"primary"}},[_vm._v("mdi-pencil")])],1)],1),_c('div',{class:[
              'force-single-line',
              _vm.subLibrary.booksInStock > 0 && 'green--text'
            ]},[_vm._v(" Books In Stock: "+_vm._s(_vm.subLibrary.booksInStock)+" ")]),_c('div',{staticClass:"force-single-line",class:[
              'force-single-line',
              _vm.subLibrary.booksDistributed > 0 && 'orange--text'
            ]},[_vm._v(" Book Out of Stock: "+_vm._s(_vm.subLibrary.booksDistributed)+" ")])])],1)]}}])},[_vm._v(_vm._s(_vm.subLibrary.name)+" ")])],1)}
var staticRenderFns = []

export { render, staticRenderFns }